import {TypographyOptions} from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Source Sans Pro, sans-serif',
  htmlFontSize: 16,
  fontWeightLight: 400,
  fontWeightRegular: 600,
  fontWeightMedium: 700,
  fontWeightBold: 900,
  h1: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '3rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  h2: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  h3: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '1.5rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  h4: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  h5: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  h6: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 700,
    fontSize: '0.75rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  body1: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  body2: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 400,
    fontSize: '75%',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    paddingBottom: '0.5rem',
    marginTop: '0',
    color: '#262A31',
  },
  button: {
    fontFamily: 'Source Sans Pro, sans-serif',
  },
};
